
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import View from '../components/layout/View';
import { ContainedButton } from '../components/core/Button';
import { withPageSession } from '../lib/session';
import React from 'react';
import { CmsPageIdentifier, useFrontendSettings } from '../lib/content';
import Link from 'next/link';
import { PageFilterType } from '../filter/types';
import Logo from '../components/layout/Logo';
import LanguageSelector from '../components/LanguageSelector';
import { isCmsPageEnabled } from '../util/content';
const LandingPage = ({ cmsPage }) => {
    const title = cmsPage?.fields?.find((field) => field.identifier === 'title')?.value?.str;
    const description = cmsPage?.fields?.find((field) => field.identifier === 'description')?.value?.str;
    const action = cmsPage?.fields?.find((field) => field.identifier === 'action')?.value?.str;
    const { showLanguageSelector } = useFrontendSettings();
    return (<div className="flex justify-center relative w-full h-screen h-screen-ios bg-black">
      <div className="landing-page-bg fixed w-full h-full object-cover bg-black"></div>

      <video autoPlay muted loop playsInline className="landing-page-video fixed w-full h-full object-cover">
        <source src="/landing-page.mp4" type="video/mp4"/>
      </video>

      <div className="landing-page-header flex justify-between items-center fixed top-4 w-full max-w-appMaxWidth h-10 px-4">
        <Logo appearance="light"/>
        <div className="absolute top-0 right-4">{showLanguageSelector && <LanguageSelector compact/>}</div>
      </div>

      <div className="landing-page-content absolute bottom-24 flex flex-col items-start md:items-center md:text-center space-y-4 md:space-y-6 px-4 text-white w-[calc(100%-32px)] md:max-w-[600px]">
        <h1 className="landing-page-content-title text-2xl md:text-4xl uppercase font-extrabold w-[80%]">{title}</h1>
        <span className="landing-page-content-description text-sm fon-medium opacity-80 leading-6" dangerouslySetInnerHTML={{ __html: description }}/>

        <Link href="/sports" passHref>
          <ContainedButton className="landing-page-content-action rounded-full">{action}</ContainedButton>
        </Link>
      </div>
    </div>);
};
LandingPage.getLayout = function getLayout(_t, _pageProps, page) {
    return (<View pageId="landing-page" addFooter={false} addHeader={false} addBottomNav={false}>
      {page}
    </View>);
};
const getServerSideProps = withPageSession(async ({ session, locale }, _pageFilterResult, pageData) => {
    const authenticated = session.get('authenticated');
    if (authenticated || !isCmsPageEnabled(pageData?.cmsPage)) {
        return {
            redirect: {
                destination: `/${locale}/sports`,
                permanent: false,
            },
        };
    }
    return {
        props: {
            ...pageData,
        },
    };
}, CmsPageIdentifier.LandingPage, [PageFilterType.PageRequiresAuthentication, PageFilterType.WebsiteRequiresAuthentication]);
export default LandingPage;

    async function __Next_Translate__getServerSideProps__19252107dc5__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__19252107dc5__ as getServerSideProps }
  